import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { getHours, getMinutes, getSeconds, set } from 'date-fns';
import { MessageService } from 'primeng/api';
import { takeWhile } from 'rxjs/operators';
import { Channel } from 'src/app/enums/channel';
import { Practice } from 'src/app/models/Practice';
import {
  getCurrentPractice,
  getCurrentPracticeSites,
} from 'src/app/practices/state/selectors';
import { EnvironmentService } from 'src/app/services/environment.service';
import { AppState } from 'src/app/state/reducers';
import {
  AddCampaign,
  ClearCreatedCampaigns,
} from '../../../campaigns/state/actions';
import { getCampaignTemplates } from '../../../templates/state/selectors';
import { Template } from '../../../models/Template';
import { GetCampaignTemplates } from '../../../templates/state/actions';
import { TemplateMergeField } from '../../../models/TemplateMergeField';
import { Media } from '../../../models/Media';
import { MediaSelectorComponent } from '../../../media/components/media-selector/media-selector.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';
import { DialogService } from 'primeng/dynamicdialog';
import { MediaType } from '../../../enums/media-type';
import { Campaign } from '../../../models/Campaign';
import { User } from '../../../models/User';
import { TemplateType } from '../../../enums/template-type';
import { Site } from '../../../models/Site';
import { getCreatedCampaignData } from '../../../campaigns/state/selectors';
import { CustomMergeFieldType } from '../../../enums/custom-merge-field-type';
import { getCurrencies } from '../../../state/selectors';
import { Currency } from '../../../models/Currency';
import { prettifyTemplateContent } from '../../../helpers/prettify-template-content';
import { practiceHasFeature } from '../../../helpers/practice-has-feature';
import { PracticeFeature } from '../../../enums/practice-feature';
import { UserService } from '../../../users/user.service';
import { CookieService } from 'ngx-cookie-service';

interface ChannelOption {
  name: string;
  code: Channel;
  icon: string;
  inactive: boolean;
}

@Component({
  selector: 'campaign-add-form',
  templateUrl: './campaign-add-form.component.html',
  styleUrls: ['./campaign-add-form.component.scss'],
})
export class CampaignAddFormComponent implements OnInit, OnDestroy {
  @ViewChild('recipientsCsv', { static: false })
  recipientsCsv?: ElementRef<HTMLInputElement>;
  @ViewChild('templatePreview')
  templatePreview?: ElementRef<HTMLTextAreaElement>;
  @ViewChild('firstInput') firstInput?: ElementRef<HTMLInputElement>;
  @Output() campaignCreated = new EventEmitter();
  @Input() buttonClass = '';
  alive = true;
  channels: ChannelOption[] = [];
  stepOneForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    message: new UntypedFormControl('', Validators.required),
    sendAt: new UntypedFormControl(new Date(), Validators.required),
    sendAtTime: new UntypedFormControl(new Date(), Validators.required),
    weekdaysOnly: new UntypedFormControl(false),
  });
  stepTwoForm = new UntypedFormGroup({
    includesColumnHeaders: new UntypedFormControl(true),
    permission: new UntypedFormControl(false, Validators.requiredTrue),
  });
  stepThreeForm = new UntypedFormGroup({
    phone: new UntypedFormControl('', Validators.required),
    clientId: new UntypedFormControl(''),
    paymentAmount: new UntypedFormControl(''),
    paymentAmountFixed: new UntypedFormControl(''),
    site: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    descriptionFixed: new UntypedFormControl(''),
    mappedFields: this.formBuilder.array([]),
  });
  selectedChannel: ChannelOption = this.channels[0];
  minDate = new Date();
  practice: Practice | null = null;
  presetMessage = '';
  deduping = false;
  recipientCount = 0;
  recipientLimit = 0;
  templates: Template[] = [];
  step = 1;
  fileUploaded = false;
  importedData: Array<string[]> = [];
  importedFilename: string | null = null;
  currentTemplate?: Template;
  showMissingCsv = false;
  stepThreeError = false;
  mappedFieldsValid = true;
  mediaType = MediaType;
  selectedMedia?: Media;
  mediaError: string | null = null;
  selectMediaDialog?: DynamicDialogRef;
  previewSrc: string | null = null;
  previewType = 'image';
  createdCampaigns: Campaign[] = [];
  dataErrors: { name: string; errors: number }[] = [];
  limitErrors: { error: string}[] = [];
  duplicates: string[] = [];
  invalidNumbers: string[] = [];
  optedOutUsers: User[] = [];
  templateType = TemplateType;
  sites: Site[] = [];
  resizeTimeout: any;
  device = 'desktop';
  previewOpen = false;
  messagePreviewText: string | null = null;
  currentTemplateFieldsString = '';
  currencies: Currency[] = [];
  practiceCurrency?: Currency;
  amountNumericError = false;
  mediaName = '';
  step3Loading = false;
  smsEnabled = true;
  whatsappEnabled = false;
  hasUsableChannel = true;
  hasMessagingEnable = false;

  constructor(
    public store: Store<AppState>,
    private messageService: MessageService,
    private environmentService: EnvironmentService,
    private formBuilder: UntypedFormBuilder,
    public dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private cookieService: CookieService
  ) {
    this.subscribeToCurrentPractice();
    this.subscribeToPracticeSites();
    this.subscribeToCurrencies();

    this.recipientLimit = this.environmentService.get('campaignLimit');
  }

  ngOnInit(): void {
    this.device = this.getDevice();
    this.subscribeToWhatsappTemplates();
    this.subscribeToCreatedCampaignData();
  }

  ngOnDestroy(): void {
    this.store.dispatch(ClearCreatedCampaigns());
    this.alive = false;
  }

  @HostListener('change', ['$event.target.files'])
  onChanges(files: File[]): void {
    if (files && files[0]) {
      this.fileUploaded = false;
      this.importedData = [];
      this.resetFormThree();
      if (this.currentTemplate) {
        this.addMappedFields(this.currentTemplate);
      }
      this.handleCsv(files[0]);
    }
  }

  @HostListener('window:resize')
  handleResize(): void {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.device = this.getDevice();
    }, 100);
  }

  getDevice(): string {
    if (window.innerWidth <= 640) {
      return 'mobile';
    }

    return 'desktop';
  }

  subscribeToCurrentPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        if (practice) {
          this.store.dispatch(GetCampaignTemplates());
          this.practice = practice;

          this.setPracticeCurrency();

          let channelCode = Channel.WHATSAPP;
          if (
            practice.whatsapp_channel === Channel.WHATSAPP360 ||
            practice.whatsapp_channel === Channel.WHATSAPP360CLOUD
          ) {
            channelCode = practice.whatsapp_channel;

            this.setMessageTemplateSelection();
            this.userService.getUserPractices().subscribe((practices) => {
              const cookiePractice = this.cookieService.get(
                'messaging-selected-practice'
              );
              const practice = practices.find(
                (practiceId) => practiceId.id === cookiePractice
              );
              if (practice) {
                const practiceFeatures = practice.features;
                this.smsEnabled = !!practiceFeatures.find(
                  (feature) => feature.slug === 'sms-messaging'
                )?.enabled;
                this.whatsappEnabled = !!practiceFeatures.find(
                  (feature) =>
                    feature.slug === 'allow-broadcasts-using-whatsapp'
                )?.enabled;

                this.hasUsableChannel = this.smsEnabled || this.whatsappEnabled;
                this.channels = [
                  {
                    name: Channel.SMS,
                    code: Channel.SMS,
                    icon: 'SMS.svg',
                    inactive: !this.smsEnabled,
                  },
                  {
                    name: Channel.WHATSAPP,
                    code: channelCode,
                    icon: 'WhatsApp.svg',
                    inactive: !this.whatsappEnabled,
                  },
                ];
                this.channels.sort((a, b) =>
                  a.inactive === b.inactive ? 0 : a.inactive ? 1 : -1
                );

                this.selectedChannel = this.channels[0];
                this.hasMessagingEnable = true;
              }
            });
          }

          if (!practiceHasFeature(practice, PracticeFeature.SMS_MESSAGING)) {
            this.channels = this.channels.filter(
              (channel) => channel.code !== Channel.SMS
            );
          }

          this.recipientLimit = Math.floor(
            practice.whatsappMaxDailyConversations * 0.8
          );
        }
      });
  }

  subscribeToCurrencies(): void {
    this.store
      .pipe(select(getCurrencies))
      .pipe(takeWhile(() => this.alive))
      .subscribe((currencies) => {
        this.currencies = currencies;
        this.setPracticeCurrency();
      });
  }

  setPracticeCurrency(): void {
    if (this.currencies.length && this.practice) {
      this.practiceCurrency = this.currencies.find(
        (currency) => currency.currencyCode === this.practice?.currency
      );
    }
  }

  subscribeToPracticeSites(): void {
    this.store
      .select(getCurrentPracticeSites)
      .pipe(takeWhile(() => this.alive))
      .subscribe((sites) => {
        this.sites = sites;

        if (sites.length > 0) {
          this.stepThreeForm.get('site')?.setValue(Number(sites[0].id));
        }
      });
  }

  subscribeToWhatsappTemplates(): void {
    this.store
      .pipe(select(getCampaignTemplates))
      .pipe(takeWhile(() => this.alive))
      .subscribe((templates) => {
        this.templates = templates;
        this.setMessageTemplateSelection();
      });
  }

  setMessageTemplateSelection(): void {
    if (this.templates.length > 0) {
      this.stepOneForm.controls.message.setValue(this.templates[0].id);
      this.updateTemplatePreview();
    }
  }

  updateTemplatePreview(): void {
    const templateId = this.stepOneForm.controls.message.value ?? '';
    const template = this.templates.find(
      (tpl) => tpl.id.toString() === templateId.toString()
    );
    this.currentTemplate = template;
    this.currentTemplateFieldsString = 'Phone Number';
    if (template && template.type === TemplateType.CampaignWithPayment) {
      this.currentTemplateFieldsString +=
        ', Description, Client ID, Amount to Pay';
    }
    if (template && template.mergeFields.length > 0) {
      this.currentTemplateFieldsString +=
        ', ' +
        template.mergeFields
          .map(
            (field) =>
              field.customName ?? prettifyTemplateContent(field.content)
          )
          .join(', ');
    }
    if (template && !template.attachMedia) {
      this.selectedMedia = undefined;
      this.previewSrc = null;
      this.previewType = '';
    }
    this.messagePreviewText = template ? template.body : null;

    if (this.templatePreview && template) {
      this.templatePreview.nativeElement.value = template.body;
    }

    if (template) {
      this.addMappedFields(template);
    }
  }

  openFileBrowser(): void {
    if (this.recipientsCsv) {
      this.recipientsCsv.nativeElement.value = '';
    }

    this.recipientsCsv?.nativeElement.click();
  }

  handleCsv(file: File): void {
    const fileReader = new FileReader();
    this.showMissingCsv = false;
    this.fileUploaded = true;
    this.importedFilename = file.name;
    fileReader.onload = async (e: any) => {
      const fileContent = e.target.result;
      const data: Array<string[]> = [];
      const regex = /"([^"]*?)"/g;
      const result = fileContent.replace(
        regex,
        (match: string, capturedString: string) => {
          const stringWithoutNewlines = capturedString.replace(/\n/g, '');
          return `"${stringWithoutNewlines}"`;
        }
      );

      result.split(/\r?\n/).forEach((row: string) => {
        const columns = row.split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/);
        if (columns.length > 0) {
          const processedColumns = columns.map((column) =>
            column.replace(/"/g, '')
          );
          data.push(processedColumns);
        }
      });

      this.importedData = data;
    };

    if (!file) {
      return;
    }

    if (file.type !== 'text/csv') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'The selected file was not a CSV',
        life: 30000,
      });
      return;
    }

    fileReader.readAsText(file);
  }

  goToStep(step: number): void {
    let valid = false;

    if (this.step === 1 && step === 2) {
      valid = this.isStepOneValid();
    }

    if (this.step === 2 && step === 3) {
      this.showMissingCsv = true;
      valid = this.isStepTwoValid();
    }

    if (valid) {
      this.step = step;
    }
  }

  goToPreviousStep(): void {
    this.step = this.step - 1;
  }

  resetForms(): void {
    this.resetFormOne();
    this.resetFormTwo();
    this.resetFormThree();

    this.createdCampaigns = [];
    this.dataErrors = [];
    this.limitErrors = [];
    this.duplicates = [];
    this.invalidNumbers = [];
    this.optedOutUsers = [];
  }

  resetFormOne(): void {
    this.selectedChannel = this.channels[0];
    this.stepOneForm.controls.name.setValue('');
    this.stepOneForm.controls.message.setValue(this.presetMessage);
    this.stepOneForm.controls.sendAt.setValue(null);
    this.stepOneForm.controls.sendAtTime.setValue(null);
    this.stepOneForm.controls.weekdaysOnly.setValue(false);
    this.recipientCount = 0;
    this.selectedMedia = undefined;
    this.previewSrc = null;
    this.mediaError = null;

    Object.keys(this.stepOneForm.controls).forEach((field) => {
      const control = this.stepOneForm.get(field);
      control?.markAsPristine({ onlySelf: true });
      control?.markAsUntouched({ onlySelf: true });
    });
  }

  resetFormTwo(): void {
    this.stepTwoForm.controls.includesColumnHeaders.setValue(true);
    this.stepTwoForm.controls.permission.setValue(false);

    Object.keys(this.stepTwoForm.controls).forEach((field) => {
      const control = this.stepTwoForm.get(field);
      control?.markAsPristine({ onlySelf: true });
      control?.markAsUntouched({ onlySelf: true });
    });
  }

  resetFormThree(): void {
    this.stepThreeForm.controls.phone.setValue('');
    this.stepThreeForm.controls.clientId.setValue('');
    this.stepThreeForm.controls.paymentAmount.setValue('');
    this.stepThreeForm.controls.paymentAmountFixed.setValue('');
    this.stepThreeForm.controls.site.setValue('');
    this.stepThreeForm.controls.description.setValue('');
    this.stepThreeForm.controls.descriptionFixed.setValue('');

    this.removeMappedFields();
    this.stepThreeError = false;
    this.amountNumericError = false;

    Object.keys(this.stepThreeForm.controls).forEach((field) => {
      const control = this.stepThreeForm.get(field);
      control?.markAsPristine({ onlySelf: true });
      control?.markAsUntouched({ onlySelf: true });
    });

    this.updateTemplatePreview();
  }

  mappedFields(): UntypedFormArray | null {
    return this.stepThreeForm.get('mappedFields') as UntypedFormArray;
  }

  addMappedFields(template: Template): void {
    this.removeMappedFields();
    template.mergeFields.forEach((mergeField: TemplateMergeField) => {
      this.addMappedField(mergeField);
    });
  }

  addMappedField(field: TemplateMergeField): void {
    const mappedFields = this.mappedFields();
    if (mappedFields) {
      mappedFields.push(this.newMappedField(field));
    }
  }

  newMappedField(field: TemplateMergeField): UntypedFormGroup {
    return this.formBuilder.group({
      fieldId: new UntypedFormControl(field.id),
      customName: new UntypedFormControl(field.customName),
      content: new UntypedFormControl(field.content),
      mappedColumn: new UntypedFormControl(''),
      fixedValue: new UntypedFormControl(''),
    });
  }

  removeMappedFields(): void {
    const mappedFields = this.mappedFields();
    if (mappedFields) {
      while (mappedFields.length !== 0) {
        mappedFields.removeAt(0);
      }
    }
  }

  submitDraft(): void {
    this.submit(true);
  }

  isStepOneValid(): boolean {
    this.mediaError = null;

    Object.keys(this.stepOneForm.controls).forEach((field) => {
      const control = this.stepOneForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    if (
      this.currentTemplate &&
      this.currentTemplate.attachMedia &&
      this.currentTemplate.mediaType
    ) {
      if (!this.selectedMedia) {
        this.mediaError = 'You must select media to send with this template.';
      } else {
        switch (this.currentTemplate.mediaType) {
          case MediaType.Image:
            if (!this.selectedMedia.mime.includes('image/')) {
              this.mediaError =
                'You must select an image to send with this template.';
            }
            break;

          case MediaType.Video:
            if (!this.selectedMedia.mime.includes('video/')) {
              this.mediaError =
                'You must select a video to send with this template.';
            }
            break;

          case MediaType.PDF:
            if (this.selectedMedia.mime !== 'application/pdf') {
              this.mediaError =
                'You must select a PDF to send with this template.';
            }
            break;
        }
      }
    }

    if (!this.stepOneForm.valid || this.mediaError) {
      return false;
    }
    return true;
  }

  isStepTwoValid(): boolean {
    Object.keys(this.stepTwoForm.controls).forEach((field) => {
      const control = this.stepTwoForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    if (this.importedData.length === 0 || !this.stepTwoForm.valid) {
      return false;
    }

    return true;
  }

  isStepThreeValid(): boolean {
    Object.keys(this.stepThreeForm.controls).forEach((field) => {
      const control = this.stepThreeForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    this.mappedFieldsValid = true;

    const fields = this.stepThreeForm.get('mappedFields')?.value;
    let allValid = true;

    if (fields) {
      fields.forEach((field: any) => {
        if (field.mappedColumn === 'fixed' && !field.fixedValue) {
          allValid = false;
        } else if (!field.mappedColumn) {
          allValid = false;
        }
      });
    }

    if (!allValid) {
      this.mappedFieldsValid = false;
      return false;
    }

    if (
      this.currentTemplate &&
      this.currentTemplate.type === TemplateType.CampaignWithPayment
    ) {
      if (!this.stepThreeForm.get('clientId')?.value) {
        this.mappedFieldsValid = false;
        return false;
      }

      if (!this.stepThreeForm.get('paymentAmount')?.value) {
        this.mappedFieldsValid = false;
        return false;
      }

      if (
        this.stepThreeForm.get('paymentAmount')?.value === 'fixed' &&
        !this.stepThreeForm.get('paymentAmountFixed')?.value
      ) {
        this.mappedFieldsValid = false;
        return false;
      }
    }

    if (!this.stepThreeForm.valid) {
      return false;
    }

    return true;
  }

  submit(draft: boolean = false): void {
    this.step3Loading = true;

    if (!this.isStepOneValid() || !this.isStepTwoValid() || this.mediaError) {
      this.step3Loading = false;
      return;
    }

    if (!this.isStepThreeValid()) {
      this.stepThreeError = true;
      this.step3Loading = false;
      return;
    }

    this.stepThreeError = false;

    if (this.practice) {
      const sendAt = set(this.stepOneForm.controls.sendAt.value, {
        hours: getHours(this.stepOneForm.controls.sendAtTime.value),
        minutes: getMinutes(this.stepOneForm.controls.sendAtTime.value),
        seconds: getSeconds(this.stepOneForm.controls.sendAtTime.value),
      }).toISOString();

      this.store.dispatch(
        AddCampaign({
          campaign: {
            name: this.stepOneForm.controls.name
              ? this.stepOneForm.controls.name.value.toString()
              : '',
            message: this.stepOneForm.controls.message
              ? this.stepOneForm.controls.message.value.toString()
              : '',
            mediaId: this.selectedMedia?.id,
            sendAt,
            channel: this.selectedChannel.code,
            practiceId: this.practice.id,
            weekdaysOnly: this.stepOneForm.controls.weekdaysOnly.value,
            selectedTemplate: true,
            draft,
            importedData: this.importedData,
            includesColumnHeaders:
              this.stepTwoForm.controls.includesColumnHeaders.value,
            phoneMapping: {
              mappedColumn: this.stepThreeForm.controls.phone.value,
            },
            clientIdMapping: {
              mappedColumn: this.stepThreeForm.controls.clientId.value,
            },
            paymentAmountMapping: {
              mappedColumn: this.stepThreeForm.controls.paymentAmount.value,
              fixedValue: this.stepThreeForm.controls.paymentAmountFixed.value,
            },
            descriptionMapping: {
              mappedColumn: this.stepThreeForm.controls.description.value,
              fixedValue: this.stepThreeForm.controls.descriptionFixed.value,
            },
            fieldMapping: [...this.stepThreeForm.get('mappedFields')?.value],
            siteId: this.stepThreeForm.controls.site.value,
          },
        })
      );
    }
  }

  subscribeToCreatedCampaignData(): void {
    this.store
      .select(getCreatedCampaignData)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        if (data.createdCampaigns) {
          if (this.createdCampaigns !== data.createdCampaigns) {
            this.step3Loading = false;
            this.createdCampaigns = data.createdCampaigns;
            this.dataErrors = data.dataErrors;
            this.limitErrors = data.limitErrors;
            this.duplicates = data.duplicates;
            this.invalidNumbers = data.invalidNumbers;
            this.optedOutUsers = data.optedOutUsers;
            this.step = 4;
          }
        }
      });
  }

  selectMedia(): void {
    this.selectMediaDialog = this.dialogService.open(MediaSelectorComponent, {
      header: 'Select Media',
      modal: true,
      width: '1000px',
      baseZIndex: 10000,
      data: {
        type: this.currentTemplate?.mediaType,
      },
    });

    this.selectMediaDialog.onClose.subscribe((media: Media) => {
      if (media) {
        this.previewSrc = null;
        this.selectedMedia = media;

        const previewTypeMap: { [key: string]: string } = {
          'image/': 'image',
          'video/': 'video',
          '/pdf': 'pdf',
        };

        const previewType = Object.keys(previewTypeMap).find((type) =>
          media.mime.includes(type)
        );

        if (previewType) {
          this.cdr.detectChanges();
          this.previewType = previewTypeMap[previewType];
          this.previewSrc = media.signedUrl;
        }
        this.mediaName = this.formatMediaName(media);
      }
    });
  }

  formatMediaName(media: { mime: string; name: string }): string {
    const extension = media.mime.split('/')[1]; // Get the media extension from the MIME type
    return `${media.name}.${extension}`;
  }

  removeMedia(): void {
    this.previewSrc = null;
    this.selectedMedia = undefined;
  }

  close(): void {
    this.store.dispatch(ClearCreatedCampaigns());
    this.resetForms();
    this.campaignCreated.emit();
  }

  togglePreview(): void {
    this.previewOpen = !this.previewOpen;
  }

  updateMapping(): void {
    if (this.currentTemplate) {
      const rowNumber = this.stepTwoForm.controls.includesColumnHeaders.value
        ? 1
        : 0;

      let tmpBody = this.currentTemplate?.body;

      const mergeFields = this.currentTemplate?.mergeFields;

      const fields = this.stepThreeForm.get('mappedFields')?.value;

      fields.forEach((item: any) => {
        if (this.currentTemplate) {
          mergeFields.forEach((mergeField: TemplateMergeField) => {
            if (item.fieldId === mergeField.id && item.mappedColumn) {
              let prefix = '';

              if (mergeField.type === CustomMergeFieldType.Currency) {
                prefix = this.practiceCurrency?.currencySymbol ?? '';
              }

              let replacement = '';
              if (item.mappedColumn === 'fixed') {
                replacement = item.fixedValue;
              } else {
                replacement =
                  this.importedData[rowNumber][Number(item.mappedColumn)];
              }

              if (!replacement.includes(prefix)) {
                replacement = prefix + replacement;
              }

              tmpBody = tmpBody?.replace(mergeField.placeholder, replacement);
            }
          });
        }
      });

      this.messagePreviewText = tmpBody;
    }
  }

  showPreview(): void {
    this.previewOpen = true;
  }

  hidePreview(): void {
    this.previewOpen = false;
  }

  validatePaymentAmountSelection(): void {
    this.amountNumericError = false;

    const selectedAmountColumn =
      this.stepThreeForm.controls.paymentAmount.value;

    if (selectedAmountColumn && selectedAmountColumn !== 'fixed') {
      this.importedData.forEach((datum, index) => {
        if (
          this.stepTwoForm.controls.includesColumnHeaders.value &&
          index === 0
        ) {
          return;
        }
        if (isNaN(Number(this.importedData[index][selectedAmountColumn]))) {
          this.amountNumericError = true;
        }
      });
    } else if (selectedAmountColumn === 'fixed') {
      if (isNaN(Number(this.stepThreeForm.controls.paymentAmountFixed.value))) {
        this.amountNumericError = true;
      }
    }
  }
}
