import { Injectable } from '@angular/core';
import {act, Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import * as CampaignActions from './actions';
import { CampaignService } from '../campaigns.service';
import { Campaign } from 'src/app/models/Campaign';
import { CampaignDto } from 'src/app/interfaces/dto/campaign.dto';
import { AppState } from 'src/app/state/reducers';
import { Store } from '@ngrx/store';
import { Noop } from 'src/app/state/actions';
import { Observable, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import {User} from '../../models/User';

@Injectable()
export class CampaignEffects {
  constructor(
    private actions$: Actions,
    private campaignService: CampaignService,
    private store: Store<AppState>,
    private messageService: MessageService
  ) {}

  getCampaigns$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActions.GetCampaigns),
    mergeMap((action) => this.campaignService.getCampaigns(action.practice)
      .pipe(
        map((result: Campaign[]) => {
          return CampaignActions.GetCampaignsSuccess({campaigns: result});
        })
      ))
    )
  );

  addCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActions.AddCampaign),
    mergeMap((action) => this.campaignService.addCampaign(action.campaign)
      .pipe(
        map((result: {
          campaigns: Campaign[];
          invalidNumbers?: string[];
          duplicates?: string[],
          optedOutUsers: User[],
          dataErrors?: {name: string, errors: number}[],
          limitErrors?: {error: string}[];
        }) => {
          return CampaignActions.AddCampaignSuccess({
            campaigns: result.campaigns,
            invalidNumbers: result.invalidNumbers || [],
            optedOutUsers: result.optedOutUsers || [],
            duplicates: result.duplicates || [],
            dataErrors: result.dataErrors || [],
            limitErrors: result.limitErrors || []
          });
        }),
        catchError((error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when creating your campaign',
            life: 5000
          });
          return of(Noop());
        })
      )
    )
  ));

  editCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActions.EditCampaign),
    mergeMap((action) => this.campaignService.editCampaign(action.campaign)
      .pipe(
        map((result: Campaign[]) => {
          return CampaignActions.EditCampaignSuccess({originalCampaignId: action.campaign.id, campaigns: result});
        }),
        catchError((error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when updating your campaign - ' + error.error.error,
            life: 5000
          });
          return of(Noop());
        })
      )
    )
  ));

  deleteCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActions.DeleteCampaign),
    mergeMap((action) => this.campaignService.deleteCampaign(action.campaign.id, action.campaign.practiceId)
      .pipe(
        map((result) => {
          return CampaignActions.DeleteCampaignSuccess({campaignId: result});
        })
      )
    )
  ));

  deleteCampaignSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActions.DeleteCampaignSuccess),
    tap(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Broadcast deleted successfully',
        life: 10000
      });
    })
  ), {dispatch: false});
}
