import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STATE_KEY } from 'src/app/state/reducers';
import { CampaignState } from './reducer';

export const getCampaignState = createFeatureSelector<CampaignState>(STATE_KEY.CAMPAIGNS);

export const getCampaigns = createSelector(
  getCampaignState,
    (state: CampaignState) => state.campaigns,
);

export const isNewCampaignOpen = createSelector(
  getCampaignState,
  (state: CampaignState) => state.newCampaignOpen,
);

export const getCreatedCampaignData = createSelector(
  getCampaignState,
  (state: CampaignState) => {
    return {
      createdCampaigns: state.createdCampaigns,
      invalidNumbers: state.invalidNumbers,
      optedOutUsers: state.optedOutUsers,
      duplicates: state.duplicates,
      dataErrors: state.dataErrors,
      limitErrors: state.limitErrors
    };
  },
);
